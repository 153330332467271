<template>
  <div v-if="isWeixin">
    <router-view/>
    <!-- 底部安全区域 -->
    <div class="van-safe-area-bottom"></div>
  </div>
  <div v-else class="no-weixin">
    <van-icon name="warning" size="50px" color="#4fadf6" />
    <h5 style="color: #999; margin-top: 20px; font-size: 16px;">请在客户端打开链接</h5>
  </div>
</template>

<script>
import {is_weixin} from '@/utils'
export default {
  name: 'App',
  data() {
    return {
      isWeixin: false
    }
  },
  created() {
    if (process.env.NODE_ENV === 'development') {
      this.isWeixin = true
    } else {
      this.isWeixin = is_weixin()
    }
  }
}
</script>

<style lang="scss">
.no-weixin {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
